// Customizable Area Start
import React from 'react';
import {withRouter} from "react-router-dom";
import { RouteComponentProps } from "react-router-dom";
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import AddCircleOutlineOutlinedIcon from '@material-ui/icons/AddCircleOutlineOutlined';
import TextField from '@material-ui/core/TextField';
import { Grid } from '@material-ui/core';
import { DashboardAccount } from './interfaces';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      paddingTop: theme.spacing(3)
    },
    avatar: {
      width: theme.spacing(7),
      height: theme.spacing(7),
      backgroundColor: '#CCC'
    },
    button: {
      color: '#FFF',
      backgroundColor: '#0F172A',
      textTransform: 'capitalize',
      fontWeight: 700,
      borderRadius: theme.spacing(2),
      fontSize:"16px",
        fontStyle:"normal",
        
        fontFamily:"Inter",
      '&:hover': {
        backgroundColor: '#0F172A'
      }
    },
    textfield: {
      backgroundColor: '#F8FAFC',
      borderColor: '#E2E8F0',
      borderRadius: '150px',
      '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
        borderColor: '#E2E8F0'
      }
    },
    input: {
      '&::placeholder': {
        color: '#64748B',
        fontSize:"16px",
        fontStyle:"normal",
        fontWeight:400,
        fontFamily:"Inter"
      }
    },
    fallbackAvatar: {
      width: '100%',
      height: '100%',
      objectFit: 'cover',
    }
  })
);

interface CreateNewPostProps extends RouteComponentProps {
  userProfile: DashboardAccount | undefined;
}

const CreateNewPost: React.FC<CreateNewPostProps> = ({ history, userProfile }) => {
  const classes = useStyles();

  return (
    <Grid container spacing={2} alignItems="center" className={classes.root}>
      <Grid item xs={1}>
        <Avatar className={classes.avatar}>
          <img src={userProfile?.attributes.profile_pic.url} className={classes.fallbackAvatar} />
        </Avatar>
      </Grid>
      <Grid item xs={9}>
        <TextField
          placeholder="Create New Post"
          variant="outlined"
          fullWidth
          multiline
          maxRows={4}
          InputProps={{
            classes: {
              root: classes.textfield,
              input: classes.input
            }
          }}
        />
      </Grid>
      <Grid item xs={2}>
        <Button
          className={classes.button}
          variant="contained"
          endIcon={<AddCircleOutlineOutlinedIcon />}
          fullWidth
          onClick={() => history.push("NewPost")}
        >
          Create
        </Button>
      </Grid>
    </Grid>
  );
}

export default withRouter(CreateNewPost);
// Customizable Area End
