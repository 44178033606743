import { createTheme } from '@material-ui/core/styles';

export const pallete = {
  yellow: '#FFC629',
  black: '#000',
  white: '#FFFFFF'
};

export const themeWhite = createTheme({
  palette: {
    primary: {
      main: pallete.yellow,
      contrastText: '#0F172A'
    },
    background: {
      default: pallete.white
    }
  }
});

export const themeYellow = createTheme({
  palette: {
    primary: {
      main: pallete.yellow,
      contrastText: '#0F172A'
    },
    background: {
      default: pallete.yellow
    }
  }
});

export const commonStyles: any = {
  mobileContainer: {
    display: 'flex',
    flexDirection: 'column',
    height: '100vh',
    padding: '20px'
  },
  mobileButton: {
    backgroundColor: '#000',
    padding: '16px',
    borderRadius: '30px',
    color: '#FFFFFF',
    fontSize: '16px',
    lineHeight: '24px',
    fontWeight: 700,
    marginBottom: '100px',
    textTransform: 'none',
    '&:hover': {
      color: '#FFFFFF',
      backgroundColor: '#000'
    }
  },
  errorMsg: {
    color: 'red',
    fontSize: '12px',
    padding: '5px 2px'
  }
};

export const webStyles = {
  buttonStyleLight: {
    backgroundColor: '#ffffff'
  },
  buttonStyleYellow: {
    backgroundColor: '#FFC629',
  },
  phoneInputLight: {
    width: '100%',
    height: '56px',
    fontWeight: 400,
    fontSize: '12px !importent'
  },
  phoneInputYellow: {
    width: '100%',
    height: '56px',
    fontWeight: 400,
    fontSize: '12px !importent',
    backgroundColor: '#FFC629',
  }
};
