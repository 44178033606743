// Customizable Area Start
import React, { Component } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Button,
} from "@material-ui/core";
import { styled } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";

const StyledDialog = styled(Dialog)({
  "& .MuiDialog-paper": {
    borderRadius: "8px 8px 32px 8px",
    padding: "0px",
    width: "100%",
    maxWidth: "500px"
  },
});

const StyledDialogTitle = styled(DialogTitle)({
  padding: "20px",
});

const StyledDialogContent = styled(DialogContent)({
  padding: "20px 25px 30px",
});

const StyledDialogActions = styled(DialogActions)({
  borderTop: "1px solid #E2E8F0",
  padding: "20px",
});

const Title = styled("h2")({
  fontSize: "22px",
  fontWeight: 600,
  fontFamily: "Inter, 'sans-serif'",
  margin: 0,
  color: "#0F172A",
  paddingBottom: "8px",
});

const Message = styled("p")({
  fontSize: "16px",
  margin: "8px 0",
  fontWeight: 400,
  color: "#0F172A",
  fontFamily: "Inter, 'sans-serif'",
});

const StyledButton = styled(Button)({
  backgroundColor: "#000",
  border: "1px solid #000",
  borderRadius: "8px",
  padding: "12px 25px",
  textTransform: "none",
  "& span": {
    color: "#fff",
    fontFamily: "Inter, 'sans-serif'",
    fontWeight: 600,
    fontSize: "16px",
  },
  "&:hover": {
    backgroundColor: "#fff",
    border: "1px solid #000",
    "& span": {
      color: "#000"
    }
  },
});

const CloseButton = styled(IconButton)({
  position: "absolute",
  right: "12px",
  top: "12px",
  "& svg": {
    color: "#334155"
  }
});

interface Props {
  isOpen: boolean;
  redirectToLogin: () => void;
}

class PasswordChangedDialog extends Component<Props> {
  render() {
    const { isOpen, redirectToLogin } = this.props;

    return (
      <StyledDialog
        open={isOpen}
        onClose={redirectToLogin}
      >
        <StyledDialogTitle>
          <CloseButton size="small" onClick={redirectToLogin}>
            <CloseIcon />
          </CloseButton>
        </StyledDialogTitle>
        <StyledDialogContent>
          <Title>Success!</Title>
          <Message>Password changed successfully.</Message>
        </StyledDialogContent>
        <StyledDialogActions>
          <StyledButton data-test-id="continue-login-button" onClick={redirectToLogin}>
            Continue to Log in
          </StyledButton>
        </StyledDialogActions>
      </StyledDialog>
    );
  }
}

export default PasswordChangedDialog;
// Customizable Area End
