// Customizable Area Start
import React, { CSSProperties } from "react";

import {
  Box,
  Typography,
  TextField,
  Button,
  IconButton,
  InputAdornment
} from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import VisibilityOutlinedIcon from "@material-ui/icons/VisibilityOutlined";
import VisibilityOffOutlinedIcon from "@material-ui/icons/VisibilityOffOutlined";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import { Formik } from "formik";
import * as Yup from "yup";

import ForgotPasswordController, {
  Props
} from "./ForgotPasswordController.web";
import { imgLogoEverybrand } from "./assets";
import PasswordChangedDialog from "../../../components/src/PasswordChangedDialog.web";

const theme = createTheme({
  palette: {
    primary: {
      main: "#FFC629",
      contrastText: "#0F172A"
    },
    background: {
      default: "#FFC629"
    }
  }
});

const webStyles: Record<string, CSSProperties> = {
  container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    paddingBottom: "20px"
  },
  everybrandLogo: {
    height: "75px",
    marginBottom: "48px"
  },
  paper: {
    backgroundColor: "#FFFFFF",
    width: "440px",
    borderRadius: "0 0 24px 0"
  },
  recoveryPasswordPaper: {
    padding: "40px"
  },
  title: {
    fontSize: "24px",
    fontWeight: 700,
    color: "#000000",
    marginBottom: "40px"
  },
  regularText: {
    marginBottom: "40px"
  },
  label: {
    fontWeight: 700,
    color: "#334155"
  },
  baseButton: {
    color: "#FFFFFF",
    backgroundColor: "#0F172A",
    borderRadius: "8px",
    textTransform: "none",
    height: "52px",
    fontWeight: 700
  },
  submitButton: {
    marginBottom: "40px"
  },
  inputWrapper: {
    marginTop: "4px",
    marginBottom: "40px"
  },
  input: {
    borderRadius: "8px",
    marginBottom: "5px"
  },
  disableInputMarginBottom: {
    marginBottom: "16px"
  },
  textButton: {
    textTransform: "none"
  },
  mustContainTitle: {
    fontSize: "16px",
    marginBottom: "8px"
  },
  mustContainText: {
    fontSize: "12px"
  },
  mustContainIcon: {
    width: "14px",
    height: " 14px",
    fill: "#94A3B8",
    marginRight: "8px"
  },
  successIconColor: {
    fill: "#34D399"
  }
};

export default class ResetPassword extends ForgotPasswordController {
  constructor(props: Props) {
    console.log("props:", props);
    super(props);
  }

  render() {
    const { isSubmittingPassword, isPasswordChanged } = this.state

    return (
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <PasswordChangedDialog
          isOpen={isPasswordChanged}
          redirectToLogin={this.goToLogin}
        />
        <Box style={webStyles.container}>
          <img
            src={imgLogoEverybrand}
            alt={this.labelEverybrand}
            style={webStyles.everybrandLogo}
          />
          <Formik
            data-test-id="resetPasswordForm"
            initialValues={{
              newPassword: this.state.newPassword,
              confirmNewPassword: this.state.confirmNewPassword
            }}
            validationSchema={Yup.object().shape(this.passwordSchema)}
            onSubmit={(values, actions) => {
              actions.setSubmitting(false);
              this.handleSubmitResetPasswordForm();
            }}
          >
            {({ handleChange, handleSubmit, errors }) => (
              <Box
                style={{
                  ...webStyles.paper,
                  ...webStyles.recoveryPasswordPaper
                }}
              >
                <Typography style={webStyles.title}>
                  {this.labelNewPassword}
                </Typography>
                <Typography style={webStyles.regularText}>
                  {this.labelEnterNewPasswordFor} {this.state.email}
                </Typography>
                {this.state.resetPasswordError && (
                  <Box
                    data-test-id="errorBlock"
                    display="flex"
                    alignItems="center"
                    height="42px"
                    borderRadius="4px"
                    borderLeft="4px solid #DC2626"
                    color="#DC2626"
                    bgcolor="#FEE2E2"
                    margin="40px 0"
                    padding="0 16px"
                  >
                    <Typography>{this.state.resetPasswordError}</Typography>
                  </Box>
                )}
                <Box>
                  <Typography style={webStyles.label}>
                    {this.labelNewPassword}
                  </Typography>
                  <TextField
                    id="newPassword"
                    data-test-id="newPassword"
                    type={this.state.displayNewPassword ? "text" : "password"}
                    variant="outlined"
                    placeholder={this.labelNewPasswordPlaceholder}
                    fullWidth
                    style={webStyles.disableInputMarginBottom}
                    InputProps={{
                      style: webStyles.input,
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={() => this.toggleNewPasswordVisibility()}
                            edge="end"
                          >
                            {this.state.displayNewPassword ? (
                              <VisibilityOutlinedIcon />
                            ) : (
                              <VisibilityOffOutlinedIcon />
                            )}
                          </IconButton>
                        </InputAdornment>
                      )
                    }}
                    error={!!errors.newPassword}
                    helperText={errors.newPassword}
                    onChange={e => {
                      handleChange(e);
                      this.setNewPassword(e.target.value);
                    }}
                  />
                </Box>
                <Box>
                  <Typography style={webStyles.label}>
                    {this.labelConfirmNewPassword}
                  </Typography>
                  <TextField
                    id="confirmNewPassword"
                    data-test-id="confirmNewPassword"
                    type={
                      this.state.displayConfirmNewPassword ? "text" : "password"
                    }
                    variant="outlined"
                    placeholder={this.labelConfirmNewPasswordPlaceholder}
                    fullWidth
                    style={webStyles.inputWrapper}
                    InputProps={{
                      style: webStyles.input,
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={() =>
                              this.toggleConfirmNewPasswordVisibility()
                            }
                            edge="end"
                          >
                            {this.state.displayConfirmNewPassword ? (
                              <VisibilityOutlinedIcon />
                            ) : (
                              <VisibilityOffOutlinedIcon />
                            )}
                          </IconButton>
                        </InputAdornment>
                      )
                    }}
                    error={!!errors.confirmNewPassword}
                    helperText={errors.confirmNewPassword}
                    onChange={e => {
                      handleChange(e);
                      this.setConfirmNewPassword(e.target.value);
                    }}
                  />
                </Box>
                <Box marginBottom="40px">
                  <Typography style={webStyles.mustContainTitle}>
                    {this.labelYourPasswordMustContain}
                  </Typography>
                  {this.passwordMustContain.map(condition => (
                    <Box
                      key={condition.label}
                      display="flex"
                      alignItems="center"
                      marginBottom="8px"
                    >
                      <CheckCircleIcon
                        style={{
                          ...webStyles.mustContainIcon,
                          ...(condition.regex.test(this.state.newPassword)
                            ? webStyles.successIconColor
                            : {})
                        }}
                      />
                      <Typography style={webStyles.mustContainText}>
                        {condition.label}
                      </Typography>
                    </Box>
                  ))}
                </Box>
                <Button
                  data-test-id="submitResetForm"
                  fullWidth
                  variant="contained"
                  style={{
                    ...webStyles.baseButton,
                    ...webStyles.submitButton
                  }}
                  disabled={isSubmittingPassword}
                  onClick={() => handleSubmit()}
                >
                  {this.labelSetNewPassword}
                </Button>
                <Button
                  style={webStyles.textButton}
                  variant="text"
                  onClick={() => this.goToLogin()}
                >
                  <Typography>
                    {this.labelBackTo} <b>{this.labelLogIn}</b>
                  </Typography>
                </Button>
              </Box>
            )}
          </Formik>
        </Box>
      </ThemeProvider>
    );
  }
}
// Customizable Area End