// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
}

interface S {
  stepOne: boolean;
  stepTwo: boolean;
  stepThree: boolean;
  loading: boolean;
  userName: string;
  userPhoneNumber: string;
  phoneNumberError: boolean;
  thoughts: string;
  themeSwitch: boolean;
  isKeyboardOpen: boolean;
  initialHeight: number;
  isMobile: boolean;
}
interface SS {
  id: any;
}
export default class EndUserEmailSignupController extends BlockComponent<
  Props,
  S,
  SS
> {
  sendOtpToUserAPI: any = "";

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials),
      getName(MessageEnum.ChangePhoneNumberMessage),
    ];

    this.state = {
      stepOne: true,
      stepTwo: false,
      stepThree: false,
      loading: false,
      userName: "",
      userPhoneNumber: "",
      phoneNumberError: false,
      thoughts: "",
      themeSwitch:
        localStorage.getItem("yellowTheme") === "true" ? true : false,
      isKeyboardOpen: false,
      initialHeight:window.innerHeight,
      isMobile: window.innerWidth <= 768 || /iPhone|iPad|iPod|Android/i.test(navigator.userAgent)
      };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  handleFocus = () => {
    if (this.state.isMobile) {
      this.setState({ isKeyboardOpen: true });
    }
  };

  handleBlur = () => {
    if (this.state.isMobile) {
      this.setState({ isKeyboardOpen: false });
    }
  };

  async receive(from: string, message: Message) {
    const messageType = getName(MessageEnum.RestAPIResponceMessage);

    if (getName(MessageEnum.ChangePhoneNumberMessage) === message.id) {
      const params = message.getData(
        getName(MessageEnum.ChangePhoneNumberMessage)
      );
      this.setState({
        stepOne: params.stepOne,
        stepThree: params.stepThree,
        stepTwo: params.stepTwo,
        thoughts: params.thoughts,
      });
    }

    if (messageType === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      const errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (apiRequestCallId === this.sendOtpToUserAPI) {
        if (responseJson.data) {
          this.setState({ loading: false });
          window.localStorage.setItem(
            "EndUserAuthToken",
            responseJson.meta.token
          );
          this.navigateToOtpScreen();
        } else if (responseJson.errors) {
          this.setState({ loading: false, phoneNumberError: true });
        }
      }
    }
  }

  handleSignInClick = () => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), "EndUserSignIn");
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    const raiseMessage: Message = new Message(
      getName(MessageEnum.EndUserSignupMessage)
    );
    message.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
    this.send(message);
  }

  navigateToOtpScreen() {
    const msg = new Message(getName(MessageEnum.NavigationMessage));
    msg.addData(getName(MessageEnum.NavigationTargetMessage), "EndUserOtpAuth");
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    const params = {
      phone: this.state.userPhoneNumber,
      name: this.state.userName,
      signUp: true,
      thoughts: this.state.thoughts,
    };
    const raiseMessage: Message = new Message(
      getName(MessageEnum.EndUserOtpValidationMessage)
    );
    raiseMessage.addData(
      getName(MessageEnum.EndUserOtpValidationMessage),
      params
    );
    msg.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
    this.send(msg);
  }

  handelThemeChange = (e: any) => {
    this.setState({ themeSwitch: e.target.checked });
    localStorage.setItem("yellowTheme", e.target.checked);
  };

  async senOtpToUser(number: any) {
    this.setState({
      loading: true,
      userPhoneNumber: number,
      phoneNumberError: false,
    });
    const data = {
      attributes: {
        full_phone_number: `+${number}`,
      },
    };

    const httpBody = {
      data: data,
    };

    const headerData = {
      "Content-Type": configJSON.signupEmailApiContentType,
    };
    let requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.sendOtpToUserAPI = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headerData)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.sendOtpToEndUserEndpoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  }
}
// Customizable Area End
